<template>
  <v-container fluid class="pa-0">
    <v-card flat tile max-width="1200" class="mx-auto d-flex flex-column">

      <v-dialog v-model="newWork" class="ml-auto mx-4">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" class="ml-auto mx-4 my-6" tile dark depressed color="#2787FF">新增船舶</v-btn>
        </template>

        <dialogCard width="670" titleTxt="新增船舶" @cancel="newWork = false" @submit="newTanker">
          <template v-slot:info>

            <v-form v-model="newWorkValid">
              <v-container fluid class="pa-0 px-12">
                <div class="d-flex align-center">
                  <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
                  <v-subheader class="title" style="color:rgba(39,135,255,1);">船舶信息（必填项）</v-subheader>
                </div>
                <v-row align="center">
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">船舶公司</label>
                    </div>
                    <v-select
                      :rules="rules" v-model="firmid"
                      :items="companiesList"
                      item-value="id" item-text="firmname"
                      background-color="#EAF4FE"
                      placeholder="请选择公司"
                      hide-details flat solo
                    ></v-select>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">船名</label>
                    </div>
                    <v-text-field
                      :rules="rules" v-model="shipname"
                      placeholder="请输入船名"
                      background-color="#EAF4FE"
                      hide-details flat solo
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">船舶自重</label>
                    </div>
                    <v-text-field
                      :rules="rules" v-model="weight"
                      placeholder="请输入船舶自重"
                      background-color="#EAF4FE"
                      hide-details flat solo suffix="公吨"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">舱总数</label>
                    </div>
                    <v-text-field
                      :rules="rules" v-model="cabinnum"
                      placeholder="请输入舱总数"
                      background-color="#EAF4FE"
                      hide-details flat solo
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          船舶总共有多少舱
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">制表水密度</label>
                    </div>
                    <v-text-field
                      :rules="rules" v-model="ptwd"
                      placeholder="请输入制表水密度"
                      background-color="#EAF4FE"
                      hide-details flat solo
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          排水量表内标注的测量时水密度
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" cols="12" class="d-flex justify-start align-center">
                    <label for class="text-left">排水量表有效期</label>
                    <v-menu
                      ref="menu" v-model="expire_time"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition" offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="date"
                          solo hide-details flat
                          prepend-inner-icon="mdi-calendar-range-outline"
                          readonly v-on="on" style="width:100px;"
                        ></v-text-field>
                      </template>
                      <v-date-picker locale="zh-cn" v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                      </template>
                      查看装载手册内的有效期
                    </v-tooltip>
                  </v-col>
                  <!-- <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">排水量表有效期</label>
                    </div>
                    <v-text-field
                      :rules="rules" v-model="expire_time"
                      placeholder="请输入排水量表有效期"
                      background-color="#EAF4FE"
                      hide-details flat solo
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          查看装载手册内的有效期
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col> -->
                </v-row>
                <div class="d-flex align-center">
                  <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
                  <v-subheader class="title" style="color:rgba(39,135,255,1);">水尺信息</v-subheader>
                </div>
                <v-row align="center">
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">垂线间距</label>
                    </div>
                    <v-text-field
                      :rules="rules" v-model="lbp"
                      placeholder="请输入垂线间距"
                      background-color="#EAF4FE"
                      hide-details flat solo
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          艏垂线和艉垂线的距离，又称LBP,可以在文档内查看
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">艏水尺距艏垂线距离</label>
                    </div>
                    <v-text-field
                      :rules="rules" v-model="df"
                      placeholder="请输入艏水尺距艏垂线距离"
                      background-color="#EAF4FE"
                      hide-details flat solo
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          艏水尺距艏垂线距离又称DF，可以查看文档查看
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">艉水尺距艉垂线距离</label>
                    </div>
                    <v-text-field
                      :rules="rules" v-model="da"
                      placeholder="请输入艉水尺距艉垂线距离"
                      background-color="#EAF4FE"
                      hide-details flat solo
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          艉水尺距艉垂线距离又称DA，可以查看文档查看
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">舯水尺距舯垂线距离</label>
                    </div>
                    <v-text-field
                      :rules="rules" v-model="dm"
                      placeholder="请输入舯水尺距舯垂线距离"
                      background-color="#EAF4FE"
                      hide-details flat solo
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          舯水尺距舯垂线距离又称DM，可以查看文档查看
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- 舱容表图片（可选） -->
                <div>
                  <div class="d-flex align-center">
                    <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
                    <v-subheader class="title" style="color:rgba(39,135,255,1);">舱容表图片（可选）</v-subheader>
                  </div>
                  <v-row v-if='imgsback.length>0'>
                    <v-col 
                      cols="12" lg="3" md="3" sm="3" xl="3" 
                      v-for="(item, i) in imgsback" :key='i'
                    >
                      <img class="img" :src="item" alt="">
                      <span @click="delimgback(i)">
                        <v-icon>mdi-file-cancel</v-icon>
                      </span>
                    </v-col>
                  </v-row>
                  <v-card 
                    v-if='imgsback.length<sizeback'
                    @click="dian()"
                    flat color="#EAF4FF" width="100" 
                    height="100" class="d-flex flex-column justify-center" 
                    style="color:#2787FF"
                  >
                    <v-icon size="50">mdi-plus</v-icon>上传图片
                  </v-card>
                  <div v-show="false" class="img-file">
                    <input ref="files" type="file" id='files' @change='fileChangeback($event)'>
                    <label for="files"></label>
                  </div>
                </div>
              </v-container>
            </v-form>

          </template>
        </dialogCard>
      </v-dialog>


      <!-- 修改船舶信息 -->
      <v-dialog v-model="modifyWork" class="ml-auto mx-4">
        <dialogCard
          width="670"
          titleTxt="修改船舶信息"
          @cancel="modifyWork = false"
          @submit="modifyTanker"
        >
          <template v-slot:info>
            <v-form v-model="newWorkValid">

              <v-container fluid class="pa-0 px-12">
                <div class="d-flex align-center">
                  <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
                  <v-subheader class="title" style="color:rgba(39,135,255,1);">船舶信息（必填项）</v-subheader>
                </div>
                <v-row align="center">
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">船舶公司</label>
                    </div>
                    <v-select
                      :rules="rules" v-model="modifyWorList.firmid"
                      :items="companiesList"
                      item-value="id" item-text="firmname"
                      background-color="#EAF4FE"
                      placeholder="请选择公司"
                      hide-details flat solo
                    ></v-select>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">船名</label>
                    </div>
                    <v-text-field
                      :rules="rules" v-model="modifyWorList.shipname"
                      placeholder="请输入船名"
                      background-color="#EAF4FE"
                      hide-details flat solo
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">船舶自重</label>
                    </div>
                    <v-text-field
                      :rules="rules" v-model="modifyWorList.weight"
                      placeholder="请输入船舶自重"
                      background-color="#EAF4FE"
                      hide-details flat solo suffix="公吨"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">舱总数</label>
                    </div>
                    <v-text-field
                      :rules="rules" v-model="modifyWorList.cabinnum"
                      placeholder="请输入舱总数"
                      background-color="#EAF4FE"
                      hide-details flat solo
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          船舶总共有多少舱
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">制表水密度</label>
                    </div>
                    <v-text-field
                      :rules="rules" v-model="modifyWorList.ptwd"
                      placeholder="请输入制表水密度"
                      background-color="#EAF4FE"
                      hide-details flat solo
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          排水量表内标注的测量时水密度
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" cols="12" class="d-flex justify-start align-center">
                    <label for class="text-left">排水量表有效期</label>
                    <v-menu
                      ref="menu" v-model="modifyWorList.expire_time"
                      :close-on-content-click="false"
                      :return-value.sync="modifyWorList.date"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="modifyWorList.date"
                          solo hide-details flat
                          prepend-inner-icon="mdi-calendar-range-outline"
                          readonly v-on="on" style="width:100px;"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="zh-cn"
                        v-model="modifyWorList.date"
                        no-title scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(modifyWorList.date)">OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                      </template>
                      查看装载手册内的有效期
                    </v-tooltip>
                  </v-col>
                  <!-- <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">排水量表有效期</label>
                    </div>
                    <v-text-field
                      :rules="rules" v-model="expire_time"
                      placeholder="请输入排水量表有效期"
                      background-color="#EAF4FE"
                      hide-details flat solo
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          查看装载手册内的有效期
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col> -->
                </v-row>
                <div class="d-flex align-center">
                  <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
                  <v-subheader class="title" style="color:rgba(39,135,255,1);">水尺信息</v-subheader>
                </div>
                <v-row align="center">
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">垂线间距</label>
                    </div>
                    <v-text-field
                      :rules="rules" v-model="modifyWorList.lbp"
                      placeholder="请输入垂线间距"
                      background-color="#EAF4FE"
                      hide-details flat solo
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          艏垂线和艉垂线的距离，又称LBP,可以在文档内查看
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">艏水尺距艏垂线距离</label>
                    </div>
                    <v-text-field
                      :rules="rules" v-model="modifyWorList.df"
                      placeholder="请输入艏水尺距艏垂线距离"
                      background-color="#EAF4FE"
                      hide-details flat solo
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          艏水尺距艏垂线距离又称DF，可以查看文档查看
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">艉水尺距艉垂线距离</label>
                    </div>
                    <v-text-field
                      :rules="rules" v-model="modifyWorList.da"
                      placeholder="请输入艉水尺距艉垂线距离"
                      background-color="#EAF4FE"
                      hide-details flat solo
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          艉水尺距艉垂线距离又称DA，可以查看文档查看
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <div class="d-flex justify-space-between align-center">
                      <label for class="text-left">舯水尺距舯垂线距离</label>
                    </div>
                    <v-text-field
                      :rules="rules" v-model="modifyWorList.dm"
                      placeholder="请输入舯水尺距舯垂线距离"
                      background-color="#EAF4FE"
                      hide-details flat solo
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          舯水尺距舯垂线距离又称DM，可以查看文档查看
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- 舱容表图片（可选） -->
                <div>
                  <div class="d-flex align-center">
                    <div style="width:4px;height:20px;background:rgba(49,144,251,1);"></div>
                    <v-subheader class="title" style="color:rgba(39,135,255,1);">舱容表图片（可选）</v-subheader>
                  </div>
                  <v-row v-if='imgsback.length>0'>
                    <v-col 
                      cols="12" lg="3" md="3" sm="3" xl="3" 
                      v-for="(item, i) in imgsback" :key='i'
                    >
                      <img class="img" :src="item" alt="">
                      <span @click="delimgback(i)">
                        <v-icon>mdi-file-cancel</v-icon>
                      </span>
                    </v-col>
                  </v-row>
                  <v-card 
                    v-if='imgsback.length<sizeback'
                    @click="dian()"
                    flat color="#EAF4FF" width="100" 
                    height="100" class="d-flex flex-column justify-center" 
                    style="color:#2787FF"
                  >
                    <v-icon size="50">mdi-plus</v-icon>上传图片
                  </v-card>
                  <div v-show="false" class="img-file">
                    <input ref="files" type="file" id='files' @change='fileChangeback($event)'>
                    <label for="files"></label>
                  </div>
                </div>
              </v-container>

            </v-form>
          </template>
        </dialogCard>
      </v-dialog>


      <v-card flat tile min-width="1200" class="mx-auto d-flex flex-column">
        <v-data-table
          :headers="headers"
          :items="rechargeRecord"
          hide-default-footer
          class="mx-4"
          :page.sync="page"
          @page-count="pageCount = $event"
          :items-per-page="8"
          style="border:2px solid rgba(39,135,255,0.3);"
        >
          <template v-slot:item.action="{ item }">
            <!-- 修改船舶信息 -->
            <div class="d-flex">
              <v-icon small class="mr-2" @click="modifyShip(item)">mdi-pencil</v-icon>
            </div>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            max-width="500"
            outlined
            :total-visible="10"
          ></v-pagination>
        </div>
      </v-card>

    </v-card>
  </v-container>
</template>

<script>
import dialogCard from "@/components/common/dialogCard";
export default {
  components: {
    dialogCard: dialogCard
  },
  data: () => ({
    rules: [v => !!v],
    page: 1,
    pageCount: 0,
    newWorkValid: false,
    newWork: false,
    modifyWork: false,
    infonList: null,
    rechargeRecord: [],
    companiesList: [],

    headers: [
      {
        text: "船名",
        align: "start",
        sortable: false,
        value: "shipname",
        class: "head-style title"
      },
      { text: "距艏垂线", value: "df", class: "head-style title" },
      { text: "距艉垂线", value: "da", class: "head-style title" },
      { text: "距舯垂线", value: "dm", class: "head-style title" },
      { text: "垂线间距", value: "lbp", class: "head-style title" },
      { text: "船舶自重", value: "weight", class: "head-style title" },
      { text: "操作", value: "action", class: "head-style title" }
    ],
    // 新建船舶信息
    firmid: '',              // 公司ID
    shipname: '',            // 船名称
    cabinnum: '',            // 舱总数
    df: '',                  // 艏水尺距艏垂线距离
    da: '',                  // 艉水尺距艉垂线距离
    dm: '',                  // 舯水尺距舯线距离
    lbp: '',                 // 垂线间距
    weight: '',              // 船舶自重
    ptwd: '',                // 随船表的制表水密度
    expire_time: '',         // 排水量表有效期
    menu: false,
    date: new Date().toISOString().substr(0, 10),

    // 修改船舶信息
    modifyWorList: {
      firmid: '',              // 公司ID
      shipname: '',            // 船名称
      cabinnum: '',            // 舱总数
      df: '',                  // 艏水尺距艏垂线距离
      da: '',                  // 艉水尺距艉垂线距离
      dm: '',                  // 舯水尺距舯线距离
      lbp: '',                 // 垂线间距
      ptwd: '',                // 随船表的制表水密度
      weight: '',              // 船舶自重
      expire_time: '',         // 排水量表有效期
      menu: false,
      date: "",
    },
    imgsback: [],            // 图片预览地址
    imgfilesback: [],        // 图片原文件，上传到后台的数据
    sizeback: 9,             // 限制上传数量
  }),
  created() {
    this.info();
  },
  methods: {
    info() {
      //获取用户基础信息
      const infonList = JSON.parse(localStorage.getItem("info"));
      //管理员散货船信息
      this.$server
      .bulkcarrierInformation({
        firmid: infonList.firmid
      })
      .then(data => {
        this.rechargeRecord = data.list;
        this.companiesList = data.firmlist;
      });
    },
    newTanker() {
      if (this.newWorkValid) {
        this.$server
          .newShipTanker({
            firmid: this.firmid,
            shipname: this.shipname,
            cabinnum: this.cabinnum,
            df: this.df,
            da: this.da,
            dm: this.dm,
            lbp: this.lbp,
            expire_time: this.expire_time,
            weight: this.weight,
            ptwd: this.ptwd,
          })
          .then(data => {
            if (data.code === 1) {
              this.info();
              this.newWork = false;
              this.bus.$emit("tips", {
                show: true,
                title: "新建成功"
              });
            } else {
              this.bus.$emit("tips", {
                show: true,
                title: data.error
              });
            }
          });
      } else {
        this.bus.$emit("tips", {
          show: true,
          title: "请输入必填项"
        });
      }
    },
    /* 图片上传 */
    dian(){
      this.$refs.files.click();
    },
    fileChangeback(e) {  // 加入图片
      // 图片预览部分
      var _this = this
      var event = event || window.event;
      var file = event.target.files
      var leng=file.length;
      for(var i=0;i<leng;i++){
          var reader = new FileReader();    // 使用 FileReader 来获取图片路径及预览效果
          _this.imgfilesback.push(file[i])
          reader.readAsDataURL(file[i]); 
          reader.onload =function(e){
          _this.imgsback.push(e.target.result);   // base 64 图片地址形成预览                                 
          };                 
      }
      // 图片上传给后台部分
      var file = _this.imgfilesback[0];
      let form = new FormData(); 
      form.append('imgFile',file);
      
      var vm = this;
      _this.$server
          .newShipTanker({
            shipid: shipid,
            file: file
          }).then((re)=>{
          console.log('re',re)
          vm.imgurl = re.data.data.url
          var imglist = vm.imglist;
          imglist.push(re.data.data) 
          vm.imglist = imglist;
          console.log('vm.imglist',vm.imglist)
      }).catch((err)=>{
          console.log(err)
      })
    },
    //删除图片的方法
    delimgback(i){
      this.imgfilesback.splice(i, 1)
      this.imgsback.splice(i, 1)
    },
    // 修改船舶信息
    modifyShip(data) {
      this.modifyWork = true;
      this.modifyWorList.firmid = data.firmid;
      this.modifyWorList.shipname = data.shipname;
      this.modifyWorList.cabinnum = data.cabinnum;
      this.modifyWorList.df = data.df;
      this.modifyWorList.da = data.da;
      this.modifyWorList.dm = data.dm;
      this.modifyWorList.lbp = data.lbp;
      this.modifyWorList.expire_time = data.expire_time;
      this.modifyWorList.weight = data.weight;
      this.modifyWorList.ptwd = data.ptwd;
    },
    modifyTanker() {
      if (this.newWorkValid) {
        this.$server
          .editShip({
            firmid: this.modifyWorList.firmid,
            shipname: this.modifyWorList.shipname,
            cabinnum: this.modifyWorList.cabinnum,
            df: this.modifyWorList.df,
            da: this.modifyWorList.da,
            dm: this.modifyWorList.dm,
            lbp: this.modifyWorList.lbp,
            expire_time: this.modifyWorList.expire_time,
            weight: this.modifyWorList.weight,
            ptwd: this.modifyWorList.ptwd,
          })
          .then(data => {
            if (data.code === 1) {
              this.info();
              this.modifyWork = false;
              this.bus.$emit("tips", {
                show: true,
                title: "修改成功"
              });
            } else {
              this.bus.$emit("tips", {
                show: true,
                title: data.error
              });
            }
          });
      } else {
        this.bus.$emit("tips", {
          show: true,
          title: "请输入必填项"
        });
      }
    }
  }
};
</script>

<style>
.head-style {
  color: #2787ff !important;
}

.flex{
  display: flex;
  flex-direction: row;
  width: 574px;
}

.img-box{
  margin-left: 10px;
  margin-right: 10px;
}

.img{
  width: 100px;
  height: 100px;
}
</style>